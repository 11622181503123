@import "./_mediaqueries.sass";

.headerLoggedIn {
    margin-top: 2rem;
    color: #fff;

    @include for-phone-only  {
        font-size: 0.9rem;
    }
}

.userId {
    color: #8ad18a;

    @include for-phone-only  {
        font-size: 0.9rem;
    }
}

.moreInfo {
    color: #fff;
    text-align: left;

        @include for-phone-only  {
            font-size: 0.9rem;
        }
}

.logout {
    cursor: pointer;
    background-color: rgba(161, 147, 135, 0.2);
    color: #e9e7dd; 
    border: none;
    padding: 0.3rem 0.8rem; 
    font-size: 0.8rem; 
    border-radius: 3px; 
    margin-top: 1rem; 
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }


