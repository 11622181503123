@import '_mediaqueries'

.main-wrapper 
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 2rem

.header-name 
  font-family: 'Segoe UI'
  margin-top: 8rem
  font-size: 6rem
  text-align: center
  font-weight: 300
  text-transform: uppercase
  letter-spacing: .08em
  background-color: #363b37
  -webkit-background-clip: text
  -moz-background-clip: text
  background-clip: text
  color: transparent
  text-shadow: rgba(245, 245, 245, 0.301) 2px 2px 2px

  @include for-phone-only 
    font-size: 2rem

.role 
  margin-top: 2rem
  display: flex
  align-items: center
  text-align: center
  opacity: 0 /* Initially hide the role section */
  transform: translateY(20px) /* Initial position for slide up animation */
  transition: opacity 0.8s ease, transform 0.8s ease


.main-wrapper.show 
  .role 
    opacity: 1 /* Show the role section */
    transform: translateY(0) /* Slide up animation */


.vertical-line 
  width: 1px
  height: 5rem
  background-color: #879489


.role-text 
  font-size: 1.5rem
  width: 20rem

  @include for-phone-only 
    font-size: 1rem
    width: 11rem


.text-role-developer 
  color: #744539
  font-family: 'Shadows Into Light'
  text-decoration: none
  font-weight: 600

  &:hover 
    font-size: 1.6rem 
    cursor: pointer 

.text-role-artist 
  color: #444f56
  text-decoration: none
  margin-left: -1rem
  font-family: 'Shadows Into Light'

  &:hover 
    font-size: 1.6rem 
    cursor: pointer 
