@import "./_mediaqueries.sass";

.developer-wrapper {
  background-color: #744539;
  margin: 0;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  text-align: justify;
  width: 100%;
  margin: auto;
  z-index: 1;

  @include for-phone-only  {
    padding-bottom: 5rem;
    }
}

.content-wrapper {
    width: 45%;

    @include for-phone-only  {
      width: 65%;
    }
    
}

.text {
  font-family: 'Quicksand';
  margin-top: 2rem;
  font-size: 1.1rem;

    @include for-phone-only  {
    font-size: 0.8rem;
    }
}
 
.background-text {
  font-family: 'Quicksand';
  position: absolute; 
  text-align: left;
  line-height: 90%;
  font-size: 7rem;
  color: rgba(161, 147, 135, 0.1); 
  z-index: -1;

    @include for-tablet-landscape-up  {
    font-size: 15rem;
    margin-top: -100px;
    color: rgba(161, 147, 135, 0.1); 
    }

    @include for-big-desktop-up {
    font-size: 19rem;
    margin-top: -140px;
    color: rgba(161, 147, 135, 0.1); 
    }
}

.skills,
.information,
.information-person,
.web3,
.project-link,
.link-socials {
  font-size: 1.2em;
  color: #fff;
}


.information-person {
  color: #cfcdc2; 
}


.web3 {
  font-weight: bold;
  color: #FFD700; 
  cursor: pointer;
}


.project-link {
  font-weight: bold;
  cursor: pointer;
  color: #87CEEB; 
}

.link-socials {
  font-style: italic;
  color: #FF69B4; 
  cursor: pointer;
}
