.header-big
  font-weight: lighter
  letter-spacing: .2rem
  font-size: 1.8rem
  text-align: center

.header-small
  font-weight: lighter
  font-size: 1.2rem
  text-align: center

.input-contact
  font-size: 0.8rem
  font-family: sans-serif
