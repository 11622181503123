@import 'Colors'

.footer-content
  height: 80px
  background-color: $white
  font-size: 0.8rem
  position: fixed
  bottom: 0
  width: 100%

.text-footer
  font-size: 0.8rem
  color: $grey
  margin-top: 40px
