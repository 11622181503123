@import '_colors'
@import '_mediaqueries'

form
  margin: 0 auto
  max-width: 20vw
  margin-bottom: 100px

  @include for-phone-only
    max-width: 70vw

input[type="text"],
input[type="email"],
textarea
  width: 100%
  padding-top: 5px
  margin-top: 5px
  margin-bottom: 20px
  border: 1px solid $lightgrey
  border-radius: 4px
  font-size: 0.8rem

  &:focus
    box-shadow: 0 0 2px rgba(0, 89, 255, 0.5)
    outline: none

input[type="submit"]
  background-color: $pink
  color: $white
  padding: 10px 20px
  border: none
  border-radius: 4px
  font-size: 0.9rem
  cursor: pointer
  margin-top: 10px
  box-shadow: 0 0 2px rgba(148, 148, 148, 0.8) 

  &:hover
    filter: brightness(1.1)

textarea
  height: 150px
